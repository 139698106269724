import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Stack } from '@fluentui/react';

// <Summary>
// Type - Styling variables 
// Description - Specifying the styles for stack(containers/divs used in fluent UI) elements.
// </Summary>
const stackTokens = { childrenGap: 40 };

// <Summary>
// Type - React Functional Component 
// Description - Contains the front and back buttons.
// </Summary>
const Navigation = ({setFront,masterNode,curr,front,back,setBack,setCurr,setMasterNode,filter,setFilter}) =>{
    
    // <Summary>
    // Type - Function
    // Description - This function is called whenever back button is clicked.
    // </Summary>
    const handleBackClick = () =>{
        
        // Taking the last data stored in the back array.
        const lastData = back[back.length-1];
        back.pop();
        
        // Pushing the current data into the front array.
        setFront([...front,{masterNode,curr,filter}]);

        // Updating all other states according to the last data.
        setMasterNode(lastData.masterNode);
        setCurr(lastData.curr);
        setFilter(lastData.filter);
        
        // Since relation is an array converting it into string to update the query parameter.
        let relationstring="";
        lastData.filter.relation.forEach((e)=>{
          relationstring+=e+',';
        })
        relationstring = relationstring.slice(0,-1);
        
        // Updating the query parameters.
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("relation", relationstring);
        queryParams.set("time", lastData.filter.time);
        queryParams.set("active", lastData.filter.active);
        queryParams.set("hideInc", lastData.filter.hideInc);
        queryParams.set("hideDev", lastData.filter.hideDev);
        queryParams.set("device",lastData.masterNode.type==='Device'?lastData.masterNode.name:lastData.masterNode.device);
        queryParams.set("node",lastData.masterNode.name);
        window.history.replaceState(null, null, "?"+queryParams.toString());
        
      }
      
      // <Summary>
      // Type - Function
      // Description - This function is called whenever front button is clicked.
      // </Summary>
      const handleFrontClick = () =>{
        
        // Taking the last data stored in the front array.
        const nextData = front[front.length-1];
        front.pop();

        // Pushing the current data into the back array.
        setBack([...back,{masterNode,curr,filter}]);

        // Updating all other states according to the last data.
        setMasterNode(nextData.masterNode);
        setCurr(nextData.curr);
        setFilter(nextData.filter);
        
        // Since relation is an array converting it into string to update the query parameter.
        let relationstring="";
        nextData.filter.relation.forEach((e)=>{
          relationstring+=e+',';
        })
        relationstring = relationstring.slice(0,-1);

        // Updating the query parameters.
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("relation", relationstring);
        queryParams.set("time", nextData.filter.time);
        queryParams.set("active", nextData.filter.active);
        queryParams.set("hideInc", nextData.filter.hideInc);
        queryParams.set("hideDev", nextData.filter.hideDev);
        queryParams.set("device",nextData.masterNode.type==='Device'?nextData.masterNode.name:nextData.masterNode.device);
        queryParams.set("node",nextData.masterNode.name);
        window.history.replaceState(null, null, "?"+queryParams.toString());
      
      }

      return (
        <Stack horizontal tokens={stackTokens} style={{width:'100%',justifyContent:'center',paddingTop:'1vh'}}>
               {back.length===0?<PrimaryButton onClick={handleBackClick} disabled>{' < '}</PrimaryButton>:<PrimaryButton onClick={handleBackClick}>{' < '}</PrimaryButton>} 
               {front.length===0?<PrimaryButton onClick={handleFrontClick} disabled>{' > '}</PrimaryButton>:<PrimaryButton onClick={handleFrontClick}>{' > '}</PrimaryButton >}
        </Stack>
      );

}

export default Navigation;