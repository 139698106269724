import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import getFilterDeviceData from '../data/filterDeviceData';
import getFilterIncidentData from '../data/filterIncidentData';

// <Summary>
// Type - Styling variables 
// Description - Specifying the styles for dropdown elements
// </Summary>
const dropdownStyles = { dropdown: { width: '100%' } };
const stackTokens = { childrenGap: 20 };

// <Summary>
// Type - React Functional Component 
// Description - Has the dropdown for Incident Status Filter
// </Summary>
const ActiveFilter = ({masterNode,nodeData,setCurr,filter,setFilter,curr,back,front,setBack}) =>{
  
  // <Summary>
  // Type - Array of Objects
  // Description - Contains all the dropdown options
  // </Summary>
  const options = [
    {key:'All',text:'All'},
    {key:'active',text:'Active'},
    {key:'mitigated',text:'Mitigated'},
    {key:'resolved',text:'Resolved'}
  ]
 
  
  // <Summary>
  // Type - Function
  // Description - This function is called whenever a new option is selected from the dropdown
  // </Summary>
  const handleChange = (e,filterSelected) =>{
    // Updating the Url by changing the Query Parameters
    var queryParams = new URLSearchParams(window.location.search); 
    queryParams.set("active", filterSelected.key);
    window.history.replaceState(null, null, "?"+queryParams.toString());
    
    // Creating a new filter object with updated active field
    const newFilter = {...filter,active:filterSelected.key};
    const deviceName = (masterNode.type==='Device')?masterNode.name:masterNode.device;
    
    // Filtering data with the new filter for the selected node
    let data = []; 
    if(masterNode.type==='Device')
      data = getFilterDeviceData(nodeData,newFilter,deviceName);
    else
      data = getFilterIncidentData(nodeData,newFilter,masterNode.name,deviceName);
    
    // Updating the states
    setBack([...back,{masterNode,curr,filter}]);
    front.length = 0;
    setCurr(data); 
    setFilter(newFilter);
  }
    
    return (
      <Stack tokens={stackTokens}>
      <Dropdown
        label="Incident Status"
        selectedKey={filter.active}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleChange}
        placeholder="Select Incident Status"
        options={options}
        styles={dropdownStyles}
      />
      </Stack>
    );
}

export default ActiveFilter;