import {useState,useEffect} from 'react';
import Legend from './Legend';
import Filter from './Filter';
import Navigation from './Navigation';
import DisplayInfo from './DisplayInfo';
import Graph from './Graph';
import Alert from './Alert';
import Navbar from './Navbar';
import getFilterDeviceData from '../data/filterDeviceData';
import getFilterIncidentData from '../data/filterIncidentData';
import '@fluentui/react/dist/css/fabric.css';
import axios from 'axios';



// <Summary>
// Type - React Functional Component 
// Description - The main component that has all the states and other components inside it.
// </Summary>
function Display() {
  
  // <Summary>
  // Type - React State 
  // Description - Contains all the graph data for a particular node without applying any filters.
  // Value - Object that has 2 properties "nodes" and "links"
  // </Summary>
  const [nodeData,setNodeData] = useState([]);
  
  // <Summary>
  // Type - React State 
  // Description - Contains the filtered graph data that is to be displayed.
  // Value - Object that has 2 properties "nodes" and "links"
  // </Summary>
  const [curr,setCurr] = useState();
  
  // <Summary>
  // Type - React State 
  // Description - Contains data about the currently selected node.
  // Value - Object that has all properties of a node(incident/device)
  // </Summary>
  const [masterNode,setMasterNode] = useState({name:''});
  
  // <Summary>
  // Type - React State 
  // Description - Contains data about the node to be displayed in Info Box.
  // Value - Object that has all properties of a node(incident/device)
  // </Summary>
  const [node,setNode] = useState([]);
  
  // <Summary>
  // Type - React State 
  // Description - Used as a stack to contain all previously visited graph.
  // Value - An array of objects that has data for previous graph displayed.
  // </Summary>
  const [back,setBack] = useState([]);
  
  // <Summary>
  // Type - React State 
  // Description - Used as a stack to contain all next graphs if user presses previous.
  // Value - An array of objects that has data for next graph to be displayed.
  // </Summary>
  const [front,setFront] = useState([]);
  
  // <Summary>
  // Type - React State 
  // Description - Contains all the filter that needs to be applied.
  // Value - A object that has 5 fields - 
  //         1)time - to store time frame to be displayed
  //         2)relation - to store all the relation filter selected by the user
  //         3)active - to store incident status selected
  //         4)hideInc - stores true/false value to hide/show incidents
  //         5)hideDev - stores true/false value to hide/show devices
  // </Summary>
  const [filter,setFilter] = useState([]);
  
  // <Summary>
  // Type - React State 
  // Description - Tells if the label needs to be displayed or not.
  // </Summary>
  const [label,setLabel] = useState(false);
  
  // <Summary>
  // Type - React State 
  // Description - Contains the filter options for the relations filter.
  // </Summary>
  const [relationFilterOptions, setRelationFilterOptions] = useState([]);
  
  // <Summary>
  // Type - React State 
  // Description - Sets to false once we receive data from backend.
  // </Summary>
  const [loading,setLoading] = useState(true);

  // <Summary>
  // Type - React State 
  // Description - Sets to true if we need to display an alert.
  // </Summary>
  const [timeAlert,setTimeAlert] = useState(false);
  
  useEffect(()=>{
    const getInfo = async () =>{
      try
      {
          // Getting and setting all the query parameters.
          var queryParams = new URLSearchParams(window.location.search);
          const getNode = queryParams.get('node');
          const getDevice = queryParams.get('device');
          const getHideInc = queryParams.get('hideInc')==='true'?true:false;
          const getHideDev = queryParams.get('hideDev')==='true'?true:false;
          const getRelation = queryParams.get('relation');
          const getTime = queryParams.get('time');
          const getActive = queryParams.get('active');
          const getLabel = queryParams.get('label')==='true'?true:false;
          const relationArray = getRelation.split(',');
          const filter = {time:getTime,relation:relationArray,active:getActive,hideInc:getHideInc,hideDev:getHideDev}
          const type = getDevice === getNode?'Device':'Incident';
    
          // Hitting the backend and getting the data.
          const res = await axios.get("/api/CorrelationOpticsWebhook?", { params: { 'nodetype': `${type}`, 'nodename': `${getNode}` } });

          // Modifying the data as required by d3 to plot the graph.
          let modifiedData = {};
          const Relation = res.data.Relation.map((e)=>{
            return {'key':e.Key,'text':e.Text};
          })
          modifiedData = {...modifiedData,Relation}
          
          const nodes = res.data.GraphObject.Nodes.map((e)=>{
            return {'device':e.Device,
            'deviceType':e.DeviceType,
            'env':e.Env,
            'event':e.Event,
            'ImpactStartDate':e.ImpactStartDate,
            'isNoise':e.IsNoise,
            'isOutage':e.IsOutage,
            'isParent':e.IsParent,
            'location':e.Location,
            'name':e.Name,
            'owningTeamName':e.OwningTeamName,
            'parentIncidentId':e.ParentIncidentId,
            'severity':e.Severity,
            'status':e.Status,
            'title':e.Title,
            'type':e.Type
          }
          })
          const links = res.data.GraphObject.Links.map((e)=>{
            return {
              'source' : e.Source,
              'target' : e.Target,
              'overlap' : e.Overlap,
              'relation' : e.Relation,
              'type' : e.Type,
              'linkDistance' : e.LinkDistance
            }
          })
      
          const GraphObject = {nodes,links}
          modifiedData = {...modifiedData,GraphObject};
  
          let data = modifiedData.GraphObject;
          const select = data.nodes.filter((e) => { return e.name === getNode });

          // throw error if data is empty
          if (select.length === 0)
              throw Error('Data not available for this node')
          
          // Updating the states as per the data received from backend. 
          setRelationFilterOptions(modifiedData.Relation);
          setNodeData(data);
          setCurr(data);
          setMasterNode(select[0]);
          setLabel(getLabel);
          setFilter(filter);
          
          // D3 requires the graph to be plotted for whole data and then apply filter for filters to work so delaying filtering data using setTimeout. 
          setTimeout(()=>{
            if(select[0].type==='Device')
            data = getFilterDeviceData(data,filter,getDevice);
            else
            data = getFilterIncidentData(data,filter,select[0].name,getDevice);
            setCurr(data);
            // Alert triggered if the Incident is older than the time filter selected.
            if(select[0].type === 'Incident'){
              var d = new Date();
              const timefil = Number(getTime)*60;
              if((d - new Date(select[0].ImpactStartDate))/60000>timefil){
              setTimeAlert(true);
              setTimeout(()=>{
                setTimeAlert(false)
              },2000);
            }
            }
          },1)
          
      }
      catch(err){
          console.log(err);
          // Can remove if noisy
          alert(err.message);
      }
    }
    getInfo();
    
    // Setting loading to false once data is updated.
    setLoading(false);
  },
  []);

  return (
      
    <div className='display'>
      <Navbar setTimeAlert = {setTimeAlert} setNodeData={setNodeData} setCurr={setCurr} setMasterNode={setMasterNode} filter={filter}/>
      {loading?<div></div>:
      <div>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
              <Filter setTimeAlert = {setTimeAlert} relationFilterOptions={relationFilterOptions} nodeData={nodeData} masterNode = {masterNode} setCurr ={setCurr} setFilter={setFilter} setBack={setBack} curr = {curr} front={front} back={back} filter={filter} label={label} setLabel={setLabel}/>
          </div>
          {timeAlert?
          <div className="ms-Grid-row">
            <Alert/> 
            <p>{timeAlert}</p>
          </div>:<div></div>}
          <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-xl12 ms-xxl10">
                <Graph setCurr={setCurr}  setMasterNode={setMasterNode} curr={curr} setBack={setBack} back={back} front={front} masterNode={masterNode} setNode={setNode} filter={filter} label={label}/>
                <Navigation setCurr = {setCurr} setMasterNode={setMasterNode} curr = {curr} setBack={setBack} setFront={setFront} back={back} front={front} masterNode={masterNode} filter={filter} setFilter={setFilter}/> 
              </div>
              <div className="ms-Grid-col ms-xl12 ms-xxl2">
              <Legend/>
              <DisplayInfo node={node}/>
              </div>
          </div>
        </div>
      
      
      </div>}
    </div>
  );
}

export default Display;
