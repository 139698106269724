import { Toggle } from '@fluentui/react/lib/Toggle';
import { Stack } from '@fluentui/react/lib/Stack';
import * as d3 from "d3";

// <Summary>
// Type - Styling variables 
// Description - Specifying the styles for stack(containers/divs used in fluent UI) elements.
// </Summary>
const stackTokens = { childrenGap: 20 };

// <Summary>
// Type - React Functional Component 
// Description - Contains the show label toggle filter.
// </Summary>
const ShowLabels = ({label,setLabel}) =>{
    
    // <Summary>
    // Type - Function
    // Description - This function is called whenever user toggles show labels button.
    // </Summary>
    const handleChange = () =>{
        // Updating the query parameters.
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("label", !label);
        window.history.replaceState(null, null, "?"+queryParams.toString());
        
        // Selecting all the label elements from graph and setting opacity to 1 if toggle value is true else to 0
        d3.selectAll('.labels')
          .style('opacity',()=>{
            if(!label)
            return 1;
            return 0;
          });

        // Updating the label state.
        setLabel(!label); 
      }
    
    return (
        <Stack tokens={stackTokens}>
            <Toggle label='Show Labels' checked={label} onChange={handleChange} />
        </Stack>
    );
}


export default ShowLabels;