import * as React from 'react';
import { DelayedRender, Callout, Text } from '@fluentui/react';

// <Summary>
// Type - React Functional Component 
// Description - Alert Component for time filter
// </Summary>
const Alert = () => {
  
  return (
      <div>
      
        <Callout
          role="status"
          aria-live="assertive"
          target={`#timefilter`}
        >
          <DelayedRender>
            <Text variant="medium">
              <b>WARNING : This Incident is older than the time filter selected!</b>
            </Text>
          </DelayedRender>
        </Callout>
      
    </div>
  );
};

export default Alert;
