import getFilterDeviceData from '../data/filterDeviceData';

import { Toggle } from '@fluentui/react/lib/Toggle';

// <Summary>
// Type - React Functional Component 
// Description - Has the toggle for hiding/showing incidents.
// </Summary>
const HideIncidents = ({masterNode,nodeData,setCurr,filter,setFilter,curr,back,front,setBack}) =>{
    
    // <Summary>
    // Type - Function
    // Description - This function is called whenever users hit the toggle button.
    // </Summary>
    const handleClick = () =>{
        // Updating the Url by changing the Query Parameters
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("hideInc", !filter.hideInc);
        window.history.replaceState(null, null, "?"+queryParams.toString());
        
        // Creating a new filter object with updated hideDev field
        const newFilter = {...filter,hideInc:!filter.hideInc};

        // Filtering data with the new filter for the selected node
        const data = getFilterDeviceData(nodeData,newFilter,masterNode.name);

        // Updating the states
        setBack([...back,{masterNode,curr,filter}]);
        front.length = 0;
        setCurr(data); 
        setFilter(newFilter);
    }
    
    return (
        <div style={{paddingLeft:'2%'}}>
            {masterNode && masterNode.type==='Device'?<Toggle label='Hide Incidents' checked={filter.hideInc} onChange={handleClick} />:<Toggle label='Hide Incidents' checked={false} disabled={true} />}
        </div>
    );
}


export default HideIncidents;