import {DetailsList} from '@fluentui/react';
import MoreInfoModal from './MoreInfoModal';
import moment from 'moment';


// <Summary>
// Type - React Functional Component 
// Description - Display Info component to display information about device and incident.
// </Summary>
const DisplayInfo = ({node}) =>{
  
  // Specifying Columns for incident and device table.
  const columns =
  [
        {
            key: 'Property',
            name: 'Property',
            fieldName: 'Property',
            type: 'string',
            minWidth: 70,
            maxWidth: 80,
            isCollapsible: false
        },
        {
            key: 'Value',
            name: 'Value',
            fieldName: 'Value',
            type: 'string',
            minWidth: 150,
            maxWidth: 600,
            isCollapsible: false
        },
  ];

  // Specifying data for incident table.
  const incidentData = [
    {
      Property: 'Impact Date',
      Value: `${moment(new Date(node.ImpactStartDate)).format('YYYY-MM-DD HH:mm:ss')}`,
    },
    {
      Property: 'Location',
      Value: `${node.location}`,
    },
    {
      Property: 'Severity',
      Value: `${node.severity}`,
    },
    {
      Property: 'Incident',
      Value: `${node.name}`
    },
    {
      Property: 'Event',
      Value: `${node.event}`
    },
    {
      Property: 'Parent',
      Value : `${node.parentIncidentId}`
    }
  ]
  // Specifying data for device table.
  const deviceData = [
    {
      Property: 'Name',
      Value: `${node.type==='Device'?node.name:node.device}`,
    },
    {
      Property: 'Type',
      Value: `${node.deviceType}`,
    },
  ]

 // Specifying Groups  
  const incidentViewGroups =  [
    { key: 'incident', name: 'Incident Info', startIndex: 0, count: 6, level: 0 },
    { key: 'device', name: 'Device Info', startIndex: 6, count: 2, level: 0 },
  ]
  const deviceViewGroups =  [
    { key: 'device', name: 'Device Info', startIndex: 0, count: 2, level: 0 },
  ]

  return (
        <div style={{height:'53vh', width:'100%',marginTop:'1vh',boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19)",overflow: 'hidden'} }>
            {node.name?<div style={{height:'88%',overflow:'hidden'}}><div style={{height:'100%',overflow:'auto',paddingTop:'0px'}}>
            <DetailsList
                items={node.type==='Incident'?[...incidentData,...deviceData]:[...deviceData]}
                groups={node.type==='Incident'?incidentViewGroups:deviceViewGroups}
                columns={columns}
                compact={true}
                selectionMode = "none"
                checkboxVisibility = {2}
                flexGrow = {4}
                justified = {1}
                constrainMode = {0}
             />     
            </div></div>:<div><h3>Hover on an incident and click on More Info to get information about that incident</h3></div>}
            {node.type==='Incident'?
            <div style={{paddingLeft:'35%', paddingTop:'2%',height:'10%'}}>
            <MoreInfoModal node={node}/></div>:<div></div>}
        </div>
    );
}

export default DisplayInfo;
