import ActiveFilter from './ActiveIncidents';
import HideIncidents from './HideIncidents';
import RelationFilter from './RelationFilter';
import TimeFilter from './TimeFilter';
import HideDevices from './HideDevices';
import ShowLabels from './ShowLabels';

import '@fluentui/react/dist/css/fabric.css';

// <Summary>
// Type - React Functional Component 
// Description - The Parent Filter Component that has all other filter components.
// </Summary>
const Filter = ({setTimeAlert,relationFilterOptions,nodeData,masterNode, setCurr,setFilter,setBack,front,curr,back,filter,label,setLabel}) =>{
    return (
      <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row" style={{background:'#e1dfdd',paddingBottom:'1%',alignContent:'center'}}>
              <div className="ms-Grid-col ms-md12 ms-lg6 ms-xl2"><RelationFilter relationFilterOptions={relationFilterOptions} nodeData={nodeData} masterNode = {masterNode} setCurr ={setCurr} setFilter={setFilter} setBack={setBack} curr = {curr} front={front} back={back} filter={filter}/></div>
              <div className="ms-Grid-col ms-md12 ms-lg6 ms-xl2"><ActiveFilter nodeData={nodeData} masterNode = {masterNode} setCurr ={setCurr} setFilter={setFilter} filter={filter} setBack={setBack} curr = {curr} front={front} back={back}/></div>
              <div className="ms-Grid-col ms-md12 ms-lg6 ms-xl2"><TimeFilter setTimeAlert = {setTimeAlert} nodeData={nodeData} masterNode = {masterNode} setCurr ={setCurr} setFilter={setFilter} filter={filter} setBack={setBack} curr = {curr} front={front} back={back}/></div>
              <div className="ms-Grid-col ms-md4 ms-lg2 ms-xl2"><HideIncidents  nodeData={nodeData} masterNode={masterNode} setCurr={setCurr} filter={filter} curr = {curr} setFilter={setFilter} setBack={setBack} front={front} back={back}/></div>
              <div className="ms-Grid-col ms-md4 ms-lg2 ms-xl2"><HideDevices  nodeData={nodeData} masterNode={masterNode} setCurr={setCurr} filter={filter} curr = {curr} setFilter={setFilter} setBack={setBack} front={front} back={back}/></div>
              <div className="ms-Grid-col ms-md4 ms-lg2 ms-xl2"><ShowLabels label={label} setLabel={setLabel}/></div>
          </div>
      </div>
        
    );
}

export default Filter;