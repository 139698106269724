// <Summary>
// Type - Function
// Description - Filter the data if a device is selected.
// Order of filter - 
//              1) According to time selected
//              2) According to relation selected
//              3) According to incident status selected
//              4) According to hide incident value selected
// </Summary>
const getFilterDeviceData = (data,filter,deviceName) =>{
    
    const {relation,time,hideInc,active} = filter;
    const selectedNode = data.nodes.filter((e)=>e.name===deviceName);

    /* 
        TIME FILTER
    */

    if(time !== 'All')
    {
        var d = new Date();
        const timefil = Number(time)*60;
        // Removes all the incidents except the selected incident that were not in the give timeframe
        const nodes = data.nodes.filter((e)=>{
            return (e.type==='Device' || (d - new Date(e.ImpactStartDate))/60000<=timefil)
        })
        // Removes all the device - incident and incident-incident related to incidents that were not in the give timeframe
        const links = data.links.filter((e)=>{
            return (e.type==='DD' || 
            (e.type==='DI' && (d - new Date(e.target.ImpactStartDate))/60000<=timefil) ||
            (e.type==='II' && (d - new Date(e.source.ImpactStartDate))/60000<=timefil && (d - new Date(e.target.ImpactStartDate))/60000<=timefil))
        })

        // Updates data to the filtered data.
        data = {nodes,links}
    }
    
    /* 
        RELATION FILTER
    */
    
    // Stores all the devices that should be present
    var devices = new Set();
    // Stores all the links that should be present
    var links = new Set();
    // Stores all the incidents that should be present
    var incidents = new Set();
    
    // Adds the selected device initially.
    devices.add(selectedNode[0])

    // Adding Device-Incident links for the selected device and adding those incidents to incident set.
    data.links.forEach((e)=>{
        if(devices.has(e.source) && e.target.type==='Incident'){  
          incidents.add(e.target);
          links.add(e);
      }
    })
    
    // Adding Incident-Incident links if any for the incidents that are in the incident set.
    data.links.forEach((e)=>{
        if(incidents.has(e.source) && incidents.has(e.target)){
            links.add(e);
        }
    })

    // For Each relation that is selected get all the links and nodes.
    relation.forEach((rel)=>{ 

        // Get all device that are one degree neighbors of the selected device and are related to it by the relation and also add device-device links between them.
        data.links.forEach((e)=>{
          if((e.source.name===deviceName && e.target.type==='Device' && e.relation===rel) || (e.target.name===deviceName && e.source.type==='Device'&& e.relation===rel)){
          devices.add(e.source);
          devices.add(e.target);
          links.add(e);
          }
        })
        
        // Add all Device- Incident links for the devices we currently have in device set.
        data.links.forEach((e)=>{
            if(devices.has(e.source) && e.target.type==='Incident'){  
              incidents.add(e.target);
              links.add(e);
          }
        })
        
        // Adding Incident-Incident links if any for the incidents that are in the incident set.
        data.links.forEach((e)=>{
            if(incidents.has(e.source) && incidents.has(e.target)){
                links.add(e);
            }
        })
        
    })
    // Update the data to newly selected nodes and links.
    data = {'nodes':[...devices,...incidents],'links':[...links]};
    
    /* 
        INCIDENT STATUS FILTER
    */
    
    if(active !=='All'){
        
        // Stores all the incidents that should be present
        let activeIncidents = new Set();
        // Stores all the devices that should be present
        let devicesOfActiveIncidents = new Set();
        // Stores all the links that should be present
        let newLinks = new Set();
        
        // Add the current device initially
        devicesOfActiveIncidents.add(selectedNode[0])
        
        // Select all the nodes whose status is same as the status filter selected.
        data.nodes.forEach((e)=>{
            
            if(e.type==='Incident' && e.status === active)
            {
                activeIncidents.add(e);
            }
        })
        
        // Add all the device incident links for these incidents and add those devices as well.
        data.links.forEach((e)=>{
            if(e.type === 'DI' && activeIncidents.has(e.target))
            {
                devicesOfActiveIncidents.add(e.source);
                newLinks.add(e);
            }
        })

        // Add any Incident-incident links present between the selected incidents.
        data.links.forEach((e)=>{
            if((e.type==='II' && activeIncidents.has(e.source) && activeIncidents.has(e.target)))
            {   
                newLinks.add(e);
            }
        })

        // Add any device-device links if present between two added devices.
        data.links.forEach((e)=>{
            if(e.type === 'DD' && devicesOfActiveIncidents.has(e.source) && devicesOfActiveIncidents.has(e.target))
            {   
                newLinks.add(e);
            }
        })

        // Update the data to filtered out data.
        let nodes = [...activeIncidents,...devicesOfActiveIncidents];
        let links = [...newLinks];
        data = {nodes,links}
            
    }

    /* 
        HIDE INCIDENTS FILTER
    */
    
    if(hideInc){
        // Select only the device nodes
        const nodes = data.nodes.filter((e)=>{
            return e.type === 'Device';
        })
        // Select only the Device-device links.
        const links = data.links.filter((e)=>{
            return e.type === 'DD';
        })
        // Update the data.
        data = {nodes,links};
    }
    else{
        // If hideInc is false and we a relation filter that contains Parent-Incident links.
        const flag = relation.indexOf('Parent Incident')===-1?false:true;
        const nodes = data.nodes;
        let links = [];
        // Removes all the Incident-incident links except with relation Parent-Incident. 
        if(flag){
            links = data.links.filter((e)=>{
                return !(e.type==='II' && e.relation!=='Parent Incident');
            })
        }
        // Removes all the Incident-incident links
        else{
            links = data.links.filter((e)=>{
                return !(e.type==='II');
            })
        }
        // Update the data.
        data = {nodes,links}
    }
    
    return data;
}

export default getFilterDeviceData;