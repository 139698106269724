import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';

import getFilterDeviceData from '../data/filterDeviceData';
import getFilterIncidentData from '../data/filterIncidentData';

// <Summary>
// Type - Styling variables 
// Description - Specifying the styles for dropdown elements
// </Summary>
const dropdownStyles = { dropdown: { width: '100%'} };
const stackTokens = { childrenGap: 20 };


// <Summary>
// Type - React Functional Component 
// Description - Has the dropdown for Relation Filter
// </Summary>
const RelationFilter = ({relationFilterOptions,nodeData,masterNode, setCurr,setFilter,setBack,front,curr,back,filter}) =>{
    
    // <Summary>
    // Type - Function
    // Description - This function is called whenever a new option is selected or removed from the dropdown
    // </Summary>
    const handleChange = (e,filterSelected) =>{
      
        // Creating a new filter object with updated relation field
        const newRelationFilter = filterSelected.selected ? [...filter.relation, filterSelected.key] : filter.relation.filter(key => key !== filterSelected.key)
        // Since the relation filter is array we need to convert it into string to update url.
        let relationstring="";
        newRelationFilter.forEach((e)=>{
          relationstring+=e+',';
        })
        relationstring = relationstring.slice(0,-1);
        const newFilter = {...filter,relation:newRelationFilter}
        
        // Updating the Url by changing the Query Parameters
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("relation", relationstring);
        window.history.replaceState(null, null, "?"+queryParams.toString());

        const deviceName = (masterNode.type==='Device')?masterNode.name:masterNode.device;
        
        // Filtering data with the new filter for the selected node
        let data = []; 
        if(masterNode.type==='Device')
          data = getFilterDeviceData(nodeData,newFilter,deviceName);
        else
          data = getFilterIncidentData(nodeData,newFilter,masterNode.name,deviceName);
        
        // Updating the states
        setBack([...back,{masterNode,curr,filter}]);
        front.length = 0;
        setFilter(newFilter);
        setCurr(data);
    }
    
    return (
      <Stack tokens={stackTokens}>
      <Dropdown
        label="Neighbors"
        selectedKeys={filter.relation}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleChange}
        placeholder="Select a neighbor"
        multiSelect
        options={relationFilterOptions}
        styles={dropdownStyles}
      />
      </Stack>
    );
}

export default RelationFilter;