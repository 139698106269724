import * as d3 from "d3";


// <Summary>
// Type - Function
// Description - This function is called whenever a graph node is being dragged on the screen.
// </Summary>

export const drag = (simulation) => {
    
    const dragstarted = (event,d) => {

        if (!event.active) {

            simulation.alphaTarget(0.3).restart();

        }
        
        d.fx = d.x;
        d.fy = d.y;

    };

    const dragged = (event,d) => {

        
        d.fx = event.x;
        d.fy = event.y;

    };

    const dragended = (event,d) => {

        if (!event.active) {

            simulation.alphaTarget(0);

        }
         
        d.fx = null;
        d.fy = null;

    };

    return d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);

};