import {Switch, Route} from 'react-router-dom';
import Display from './components/Display';


// <Summary>
// Type - React Functional Component 
// Description - Contains all the routes.
// </Summary>
const Routes = () =>{
    return <Switch>
        <Route path = '/' component={Display}>
        </Route>
    </Switch>
}

export default Routes;