import { saveAs } from 'file-saver';
import * as htmlToImage from 'html-to-image';

// <Summary>
// Type - Function
// Description - Save the graph as jpeg file.
// </Summary>
export const saveImage = () =>{
	htmlToImage.toPng(document.getElementsByClassName("Target")[0])
	.then(function (dataUrl) {
		saveAs(dataUrl, 'Alert Graph.jpeg');
	  });
    
}
