import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './Routes';


// <Summary>
// Type - React Functional Component 
// Description - Main Component that calls all other components.
// </Summary>
function App() {

  return (
      <div className="App">
      <Router>
        <Routes/>
      </Router>
      </div>
  );
}

export default App;
