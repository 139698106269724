import { ThemeProvider } from '@fluentui/react';
import { CoherenceCustomizations } from "@coherence-design-system/styles";
import { CoherenceHeader } from "@coherence-design-system/controls"
import {saveImage} from '../savegraph/savegraph';
import getFilterIncidentData from '../data/filterIncidentData';
import axios from 'axios';

// <Summary>
// Type - React Functional Component 
// Description - Navbar component
// </Summary>
function Navbar({setNodeData, setCurr, setMasterNode, filter, setTimeAlert}) {
  
  const handleSearch = async (e) =>{
    try
      {
          // Hitting the backend and getting the data.
          const res = await axios.get("/api/CorrelationOpticsWebhook?", { params: { 'nodetype': `Incident`, 'nodename': `${e}` } });

          // Modifying the data as required by d3 to plot the graph.
          let modifiedData = {};
          const Relation = res.data.Relation.map((e)=>{
            return {'key':e.Key,'text':e.Text};
          })
          modifiedData = {...modifiedData,Relation}
          
          const nodes = res.data.GraphObject.Nodes.map((e)=>{
            return {'device':e.Device,
            'deviceType':e.DeviceType,
            'env':e.Env,
            'event':e.Event,
            'ImpactStartDate':e.ImpactStartDate,
            'isNoise':e.IsNoise,
            'isOutage':e.IsOutage,
            'isParent':e.IsParent,
            'location':e.Location,
            'name':e.Name,
            'owningTeamName':e.OwningTeamName,
            'parentIncidentId':e.ParentIncidentId,
            'severity':e.Severity,
            'status':e.Status,
            'title':e.Title,
            'type':e.Type
          }
          })
          const links = res.data.GraphObject.Links.map((e)=>{
            return {
              'source' : e.Source,
              'target' : e.Target,
              'overlap' : e.Overlap,
              'relation' : e.Relation,
              'type' : e.Type,
              'linkDistance' : e.LinkDistance
            }
          })
      
          const GraphObject = {nodes,links}
          modifiedData = {...modifiedData,GraphObject};
  
          let data = modifiedData.GraphObject;
          
          
          const select = data.nodes.filter((et)=>{return et.name===e});

          // throw error if data is empty
          if (select.length === 0)
              throw Error('Data not available for this Incident')
          
          // Updating the query parameters.
          var queryParams = new URLSearchParams(window.location.search);
          queryParams.set("node", select[0].name);
          queryParams.set("device", select[0].device);
          
          // Updating the states as per the data received from backend. 
          setNodeData(data);
          setCurr(data);
          setMasterNode(select[0]);
          

          // D3 requires the graph to be plotted for whole data and then apply filter for filters to work so delaying filtering data using setTimeout. 
          setTimeout(()=>{
            data = getFilterIncidentData(data,filter,select[0].name,select[0].device);
            setCurr(data);
    
            // Alert triggered if the Incident is older than the time filter selected.
            if(select[0].type === 'Incident'){
              var d = new Date();
              const timefil = Number(filter.time)*60;
              if((d - new Date(select[0].ImpactStartDate))/60000>timefil){
              setTimeAlert(true);
              setTimeout(()=>{
                setTimeAlert(false)
              },2000);
            }
            }
          },1)
          
      }
      catch(err){
        console.log("Error Occured")
        console.log(err);
        // alert('Incident not found! Please enter a valid incident Id');
        alert(err.message)
      }
    
  }
  return (
    <ThemeProvider {...CoherenceCustomizations}>
    <CoherenceHeader
      appNameSettings={{
        label: 'Alert Correlation Discovery Optics',  
        linkUrl: `${window.location.href}`
      }}
      searchSettings={{
        onSearch: handleSearch,
        placeholder: 'Search By Incident Id'
      }}
      farRightSettings={{
          additionalItems: [
              {
                  key: 'Save',
                  text: 'Save',
                  iconProps: {
                      iconName: 'Save',
                      onClick: saveImage
                  },
                  ariaLabel: 'Save'
              }
          ]
      }}
      />
    </ThemeProvider>
  );
}

export default Navbar;
