import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';

import getFilterDeviceData from '../data/filterDeviceData';
import getFilterIncidentData from '../data/filterIncidentData';

// <Summary>
// Type - Styling variables 
// Description - Specifying the styles for dropdown elements.
// </Summary>
const dropdownStyles = { dropdown: { width: '100%' } };
const stackTokens = { childrenGap: 20 };

// <Summary>
// Type - React Functional Component 
// Description - Has the dropdown for Time Filter.
// </Summary>
const TimeFilter = ({setTimeAlert,masterNode, nodeData,setCurr,setFilter,filter,curr,back,front,setBack}) =>{
    
    // <Summary>
    // Type - Array of Objects
    // Description - Contains all the dropdown options
    // </Summary>
    const options = [
      {key:'All',text:'All'},
      {key:'2',text:'Last 2 Hrs'},
      {key:'4',text:'Last 4 Hrs'},
      {key:'6',text:'Last 6 Hrs'},
      {key:'8',text:'Last 8 Hrs'},
      {key:'24',text:'Last 24 Hrs'},
      {key:'72',text:'Last 3 Days'},
      {key:'168',text:'Last 1 Week'},
      {key:'336',text:'Last 2 Weeks'},
      {key:'504',text:'Last 3 Weeks'},
      {key:'672',text:'Last 4 Weeks'}
    ]
    
    // <Summary>
    // Type - Function
    // Description - This function is called whenever a new option is selected from the dropdown
    // </Summary>
    const handleChange = (e,filterSelected) =>{

      // Updating the Url by changing the Query Parameters
      var queryParams = new URLSearchParams(window.location.search);
      queryParams.set("time", filterSelected.key);
      window.history.replaceState(null, null, "?"+queryParams.toString());
      
      // Creating a new filter object with updated time field
      const newFilter = {...filter,time:filterSelected.key};
      const deviceName = (masterNode.type==='Device')?masterNode.name:masterNode.device;
      
      // Filtering data with the new filter for the selected node
      let data = []; 
      if(masterNode.type==='Device')
        data = getFilterDeviceData(nodeData,newFilter,deviceName);
      else
        data = getFilterIncidentData(nodeData,newFilter,masterNode.name,deviceName);

      // An alert pops up if the selected incident is older than the time filter selected.
      if(masterNode.type === 'Incident'){
        var d = new Date();
        const timefil = Number(filterSelected.key)*60;
        if((d - new Date(masterNode.ImpactStartDate))/60000>timefil){
        setTimeAlert(true);
          setTimeout(()=>{
            setTimeAlert(false)
        },2000);
        }
      }

      // Updating the states.
      setBack([...back,{masterNode,curr,filter}]);
      front.length = 0;
      setCurr(data); 
      setFilter(newFilter);
    }
    
    return (
       <Stack tokens={stackTokens}>
       <Dropdown
         label="Time"
         selectedKey={filter.time}
         // eslint-disable-next-line react/jsx-no-bind
         onChange={handleChange}
         placeholder="Select a time frame"
         options={options}
         styles={dropdownStyles}
         id = 'timefilter'
       />
       </Stack>
    );
}

export default TimeFilter;