import {useEffect} from 'react';
import * as d3 from "d3";

import {drag} from '../d3/drag';
import DeviceIcon from '../img/DeviceIcon.jpg';
import ActiveIncident from '../img/ActiveIncident.jpg';
import UnknownIncident from '../img/UnknownIncident.jpg';
import MitigatedIncident from '../img/MitigatedIncident.jpg';
import ResolvedIncident from '../img/ResolvedIncident.jpg';
import getFilterDeviceData from '../data/filterDeviceData';
import getFilterIncidentData from '../data/filterIncidentData';
import axios from 'axios';

// <Summary>
// Type - React Functional Component 
// Description - the Graph component contains all the nodes and the links
// </Summary>
const Graph = ({curr,setCurr,masterNode,setMasterNode,setNode,back,setBack,front,filter,label}) =>{
    
    useEffect(()=>{
      if(curr)  
      drawChart();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[curr,masterNode]
    // useEffect is called whenever curr or masterNode states are updated.
    )
    
    // <Summary>
    // Type - Function
    // Description - This function draws the graph according to the current data.
    // </Summary>
    const drawChart=()=> {
        d3.selectAll('.Target > *').remove();
        var svgCont = d3.select('.svgContainer');
        var width = svgCont.node().getBoundingClientRect().width;
        var height = svgCont.node().getBoundingClientRect().height;
        
        var svg = d3.select('.Target').attr("height",height).attr('width',width);
        
        // defs for icons that are used for the nodes and arrows for parent-child links.
        var defs = svg.append('svg:defs');
            // Device Icon def
            defs.append('svg:pattern')
                .attr('id', 'device')
                .attr('width', '30')
                .attr('height', '30')
                .append('svg:image')
                .attr('xlink:href', DeviceIcon)
                .attr('x', +5)
                .attr('y', +5)
                .attr('width', 30)
                .attr('height', 30);
            
            // Resolved Incident Icon def
            defs.append('svg:pattern')
                .attr('id', 'ResolvedIncident')
                .attr('width', '30')
                .attr('height', '30')
                .append('svg:image')
                .attr('xlink:href', ResolvedIncident)
                .attr('x', 5)
                .attr('y', 7)
                .attr('width', 30)
                .attr('height', 25);

            // Unknown Incident Icon def
            defs.append('svg:pattern')
                .attr('id', 'UnknownIncident')
                .attr('width', '30')
                .attr('height', '30')
                .append('svg:image')
                .attr('xlink:href', UnknownIncident)
                .attr('x', 5)
                .attr('y', 7)
                .attr('width', 25)
                .attr('height', 20);

            // Mitigated Incident Icon def    
            defs.append('svg:pattern')
                .attr('id', 'MitigatedIncident')
                .attr('width', '30')
                .attr('height', '30')
                .append('svg:image')
                .attr('xlink:href', MitigatedIncident)
                .attr('x', 5)
                .attr('y', 7)
                .attr('width', 30)
                .attr('height', 25);

            // Active Incident Icon def    
            defs.append('svg:pattern')
                .attr('id', 'ActiveIncident')
                .attr('width', '30')
                .attr('height', '30')
                .append('svg:image')
                .attr('xlink:href', ActiveIncident)
                .attr('x', 5)
                .attr('y', 7)
                .attr('width', 30)
                .attr('height', 25);

            // Arrow marker def    
            defs.append('svg:marker')
                .attr('id','markerArrow')
                .attr('markerWidth','14')
                .attr('markerHeight','14')
                .attr('refX','7')
                .attr('refY','7')
                .attr('orient','auto')
                .attr('markerUnits','userSpaceOnUse')
                .append('path')
                .attr('fill','black')
                .attr('d','M0,0 L0,14 L14,7 z');
      
        // Stores the last hovered upon node 
        let currentTarget = null;

        // Setting the zoom
        var g = svg.append('g').attr("transform", "translate(" + 0 + "," + 0+ ")");
        svg.call(d3.zoom().extent([[0, 0], [width, height]]).scaleExtent([0.5,4]).on("zoom", (event)=>{
          g.attr("transform", event.transform); 
        })).on("dblclick.zoom", null)
        
        
        // Simulation stores how the graph should behave like charges between the nodes, gravity,etc.
        var simulation = d3.forceSimulation(curr.nodes)
          .force("link", d3.forceLink(curr.links).id(function (d) { return d.name; }).distance((d) => {
            switch (d.type) {
                case "DD": return 50;
                case "DI": return 10;
                default: return 10;
               }
          }).strength(1.5))
          .force("charge", d3.forceManyBody().strength(-5000))
          .force("center", d3.forceCenter(width/2, height/2 ))
          .force('x', d3.forceX(2*width ).strength(0.05))
          .force('y',  d3.forceY(2*height ).strength(0.05))
          .on('tick',ticked);
          
        // Making links between the nodes.
        const link = g
          .selectAll('path.link')
          .data(curr.links)
          .enter()
          .append('path')
          .attr('stroke',(d)=>{
              switch (d.relation) {
                  case "Device Incident": return "blue";
                  case "LLDP Neighbors" : return "#5c005c";
                  case "CDP Neighbors" : return "#004b50";
                  case "ISIS Neighbors" : return "#00bcf2";
                  default: return "green";
              }   
            })
          .attr('stroke-width',(d)=>{
            switch (d.relation) {
              case "Device Incident": return 2;
              case "LLDP Neighbors" : return 3;
              case "CDP Neighbors" : return 3;
              case "ISIS Neighbors" : return 3;
              default: return 3;
          }  
          })
          .attr('fill','none')
          .attr('marker-mid',(d)=>{
            if(d.type==='II' && d.relation==='Parent Incident')
            return "url(#markerArrow)";
            else
            return "none";
          });
        
        // Enters all the nodes done after links so that nodes come above links if there is overlap
        var node = g.append('g')
          .attr("class", "nodes")
          .selectAll("g")
          .data(curr.nodes,(d)=>d.name)
          .enter()
          .append("g")
          .append("circle")
          .attr("r", 20)
          .style("fill", (d) => {
            if(d.type=== "Device")
            return "url(#device)";
            else if(d.status === 'active')
            return "url(#ActiveIncident)";
            else if(d.status === 'resolved')
            return "url(#ResolvedIncident)";
            else if(d.status === 'mitigated')
            return "url(#MitigatedIncident)";
            else 
            return "url(#UnknownIncident)";
          
          })
          .attr('stroke-dasharray', (d) =>{
            if(d.type=== 'Incident' && d.isParent === true)
              return (10,10);
          })
          .attr('stroke', (d)=>{
            if(d.name === masterNode.name)
              return 'black';
            else if(d.type=== 'Incident' && d.isParent === true)
              return '#3279a8'; 
          })
          .attr('stroke-width', (d)=>{
            if(d.name === masterNode.name)
            return 4;
            else if(d.type=== 'Incident' && d.isParent === true)
            return 4;
            else return 0;
          })

        
        // A container to contain all the labels.
        const textContainer = g
          .selectAll('g.label')
          .data(curr.nodes)
          .enter()
          .append('g')
          .style('opacity',()=>{
            if(label)
            return 1;
            return 0;
          });

        // Setting containers height and width according to the text length
        textContainer
          .attr("class", "labels")
          .append("rect")
            .attr("width", (d)=>{
              if(d.type==='Device')
              return Math.max(d.name.length,d.deviceType.length)*6.5;
              else if(d.deviceType)
              return (d.deviceType+"  "+d.event).length*6.5;
              else 
              return (d.name.length*7);
            })
            .attr("height", (d)=>{
              if(d.type==='Device')
              return 24;
              else 
              return 12;
            })
            .attr("fill", "#edebe9")
            .attr("rx", 4)

          // Adds text to the labels
          textContainer.append('text')
            .text((d)=>{
              if(d.type==='Device')
              return d.deviceType;
              else if(d.deviceType)
              return d.deviceType+" "+ d.event;
              else 
              return d.name;
            })
            .attr('font-size',12)
            .attr('pointer-events','none')
            .attr("transform", "translate(2, 10)");

            // In case of devices also add device name
            textContainer.append('text')
            .text((d)=>{
              if(d.type==='Device')
              return d.name;
            })
            .attr('font-size',12)
            .attr('pointer-events','none')
            .attr("transform", "translate(2, 22)");
        
        // Calls drag to handle dragging of nodes
        node.call(drag(simulation));
        
        // Adds card to nodes on hovering
        const card = g
          .append("g")
          .attr("pointer-events", "none")
          .attr("display", "none");
          
        const cardBackground = card.append("rect")
            .attr("width", 100)
            .attr("height", 50)
            .attr("fill", "#edebe9")
            .attr("stroke", "#333")
            .attr("rx", 4);
          
        const cardTextName = card
            .append("text")
            .attr("transform", "translate(8, 20)")
            .attr("pointer-events", "none");
          
        const moreCardInfo = card
            .append("text")
            .attr("transform", "translate(8, 40)")
            .attr("pointer-events", "auto")
            .attr('cursor','pointer');
          
        const cardTextPosition = card
            .append("text")
            .attr("font-size", 10)
            .attr("transform", "translate(8, 20)");
        

        // Function call on hovering
        node.on("mouseover", (event,d) => {
              
              node.style("cursor", "pointer"); 
              // Setting the target.
              currentTarget = event.target;
              card.attr("display", "block");
              // Add incident/device name and a MoreInfo button.
              cardTextName.text(d.name);
              // Set Node state to the currently hovered node if More Info is clicked.
              moreCardInfo.text('More Info').attr('fill','blue').on('click',()=>{
                setNode(d);
              });
              
              const nameWidth = Math.max(cardTextName.node().getBBox().width,moreCardInfo.node().getBBox().width);
              const positionWidth = cardTextPosition.node().getBBox().width;
              const cardWidth = Math.max(nameWidth, positionWidth);
          
              cardBackground.attr("width", cardWidth + 16);
          
              simulation.alphaTarget(0).restart();
          
        });
          
        // Function to be called when mouse leaves a node
        node.on("mouseout", () => {
          node.style("cursor", "default"); 
          let previousTarget = currentTarget;    
          // Calling SetTimeout to remove the hover card 2 seconds after the pointer is removed.
          setTimeout(()=>{
              if(previousTarget===currentTarget){
              card.attr("display", "none");
              currentTarget = null;
              }
            },2000);
        }); 
          
          
        // Function call when a node is clicked
        node.on('click',async (event,d)=>{
            try
            {
              // Checks if the node clicked on is different from the currently selected node or not.
              if(d!==masterNode){

              // Pushing the current data to the back stack and updating its state.
              setBack([...back,{masterNode,curr,filter}]);
              front.length = 0;
              
              
              // Hitting the backend and getting the data.
              const deviceName = (d.type==='Device')?d.name:d.device;
              const res = await axios.get("/api/CorrelationOpticsWebhook?", { params: { 'nodetype': `${d.type}`, 'nodename': `${d.name}` } });

              // Modifying the data as required by d3 to plot the graph.
              let modifiedData = {};
              const Relation = res.data.Relation.map((e)=>{
                return {'key':e.Key,'text':e.Text};
              })
              modifiedData = {...modifiedData,Relation}
              
          
              const nodes = res.data.GraphObject.Nodes.map((e)=>{
                return {'device':e.Device,
                'deviceType':e.DeviceType,
                'env':e.Env,
                'event':e.Event,
                'ImpactStartDate':e.ImpactStartDate,
                'isNoise':e.IsNoise,
                'isOutage':e.IsOutage,
                'isParent':e.IsParent,
                'location':e.Location,
                'name':e.Name,
                'owningTeamName':e.OwningTeamName,
                'parentIncidentId':e.ParentIncidentId,
                'severity':e.Severity,
                'status':e.Status,
                'title':e.Title,
                'type':e.Type
              }
              })
              const links = res.data.GraphObject.Links.map((e)=>{
                return {
                  'source' : e.Source,
                  'target' : e.Target,
                  'overlap' : e.Overlap,
                  'relation' : e.Relation,
                  'type' : e.Type,
                  'linkDistance' : e.LinkDistance
                }
              })
          
              const GraphObject = {nodes,links}
              modifiedData = {...modifiedData,GraphObject};
              
              
              let data = modifiedData.GraphObject;
              const select = data.nodes.filter((e) => { return e.name === d.name });


              // throw error if data is empty
              if (select.length === 0)
                  throw Error('Data not available for this node')


              // Updating the Query Parameters.
              var queryParams = new URLSearchParams(window.location.search);
              queryParams.set("node", d.name);
              queryParams.set("device", deviceName);
              
              // Updating the states as per the data received from backend.
              setCurr(data);

               // D3 requires the graph to be plotted for whole data and then apply filter for filters to work so delaying filtering data using setTimeout. 
              setTimeout(()=>{
                if(d.type==='Device'){
                  data = getFilterDeviceData(data,filter,deviceName);
                  queryParams.set("hideDev", "false");
                  setCurr(data);
                  setMasterNode(d);
                }
                else{
                  data = getFilterIncidentData(data,filter,masterNode.name,deviceName);
                  queryParams.set("hideInc", "false");
                  setCurr(data);
                  setMasterNode(d);
                }
              },1)
              
              window.history.replaceState(null, null, "?"+queryParams.toString());
              
              }
          }
          catch(err){
                console.log(err);
                // Can remove if noisy
                alert(err.message);
          }
        })
            
        // Generates links as lines
        const lineGenerator = d3.line().curve(d3.curveCardinal);
             
        // Function to handle the plotting of the nodes and links and their coordinates.
        function ticked() {
           
            // Setting Coordinates of nodes
            node.attr("transform", function (d) {
               return "translate(" + d.x + "," + d.y + ")";
            })
          
            // Setting Coordinates of labels of nodes
            textContainer.attr("transform", function (d) {
              return "translate(" + (d.x+21) + "," + (d.y) + ")";
            
            })
            
            //Drawing lines for each link
            link.attr('d', (d)=>{
              let mid = [
                (d.source.x + d.target.x) / 2,
                (d.source.y + d.target.y) / 2
               ];
              if (d.overlap > 0) {
                const index = d.overlap;
                const distance = Math.sqrt(
                    Math.pow(d.target.x - d.source.x, 2) +
                    Math.pow(d.target.y - d.source.y, 2)
                );
    
                
    
                const slopeX = (d.target.x - d.source.x) / distance;
                const slopeY = (d.target.y - d.source.y) / distance;
                
                // Handles curves in case of overlap.
                const curveSharpness = 15*index;
                mid[0] += curveSharpness * slopeY;
                mid[1] -= curveSharpness * slopeX;
    
            }
              return lineGenerator(
              [[d.source.x,d.source.y],
              mid,
              [d.target.x,d.target.y]
          ])
          })

          // Add a hover card if currentTarget is not NULL i.e. a node was hovered over
          if (currentTarget) {
    
            
            const dist = currentTarget.r.baseVal.value + 3;
    
            const xPos = currentTarget.transform.baseVal[0].matrix.e + dist;
            const yPos = currentTarget.transform.baseVal[0].matrix.f - dist;
    
            card.attr("transform", `translate(${xPos}, ${yPos})`);
    
        }
            
        }   
    
      }

    return (
      <div style={{paddingTop:'2vh'}}>
          <div className='svgContainer' style={{height:"82vh", width:"98%",marginLeft:'1%',boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19)",overflow:'hidden'}}>
            
            <svg className="Target">
          
            </svg>  
            
        </div>
      </div>
    );
}

export default Graph;