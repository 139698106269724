import React, { useState } from 'react';
import { CoherenceModal } from '@coherence-design-system/controls';
import { PrimaryButton } from '@fluentui/react';
import { CoherenceDataGrid } from '@coherence-design-system/controls';
import moment from 'moment';

// <Summary>
// Type - React Functional Component 
// Description - Display Info Model that pops up when more info is clicked in the info box.
// </Summary>
const MoreInfoModal = ({node}) =>{

    // <Summary>
    // Type - React State 
    // Description - Contains true if model is to be shown else false
    // Value - True/False
    // </Summary>
    const [toggle,setToggle] = useState(false);

    // Specifying Columns for incident and device table.
    const columns =
    [
        {
            key: 'Property',
            name: 'Property',
            fieldName: 'Property',
            type: 'string',
            minColumnWidth: 100,
            isResizeable:true
        },
        {
            key: 'Value',
            name: 'Value',
            fieldName: 'Value',
            type: 'string',
            minColumnWidth: 450,
            isResizeable:true
        },
    ];

    // Specifying data for incident table.
    const incidentData = [
      {
        Property: 'Impact Date',
        Value: `${moment(new Date(node.ImpactStartDate)).format('YYYY-MM-DD HH:mm:ss')}`,
      },
      {
        Property: 'Title',
        Value: `${node.title}`,
      },
      {
        Property: 'Owning Team',
        Value: `${node.owningTeamName}`,
      },
      {
        Property: 'Location',
        Value: `${node.location}`,
      },
      {
        Property: 'Severity',
        Value: `${node.severity}`,
      },
      {
        Property: 'Parent',
        Value: `${node.parentIncidentId}`
      },
      {
        Property: 'IsNoise',
        Value: `${node.isNoise===0?'No':'Yes'}`
      },
      {
        Property: 'IsOutage',
        Value: `${node.isOutage===0?'No':'Yes'}`
      },
      {
        Property: 'Event',
        Value: `${node.event}`
      }
    ]

    // Specifying data for device table.
    const deviceData = [
      {
        Property: 'DeviceName',
        Value: `${node.type==='Device'?node.name:node.device}`,
      },
      {
        Property: 'DeviceType',
        Value: `${node.deviceType}`,
      },
    ]
  
    return (
        <div>
            <PrimaryButton
                text={'More Info'}
                onClick={()=>{setToggle(true)}}
            />
            <CoherenceModal
                isOpen={toggle}
                modalWidth={'large'}
                height={'responsive'}
                onDismiss={()=>{setToggle(false)}}
                title={`Incident: ${node.name}`}
            >
            {node.name?<div style={{height:'95%'}}>
                <div >
                <h3>Incident Info</h3>
                <CoherenceDataGrid
                    listConfig={columns}
                    data={incidentData}
                    isScrollable={false}
                />
                </div>
                <div>
                  <h3> Device Info</h3>
                  <CoherenceDataGrid
                    listConfig={columns}
                    data={deviceData}
                    isScrollable={false}
                    
                />      
            </div></div>:<div></div>}
            </CoherenceModal>
        </div>
    );
   

}

export default MoreInfoModal;